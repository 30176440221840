import React from 'react'
import styled from 'styled-components'
import { ScreenBg } from './ScreenBg'
import ScreenBody from './ScreenBody'
import ScreenHeader from './ScreenHeader'

export function Screen() {
  return (
    <ScreenWrapper className="screen">
      <svg width="595" height="428" viewBox="0 0 595 428" fill="none" xmlns="http://www.w3.org/2000/svg">
        {/* <g filter="url(#filter0_b)"> */}
        <path
          d="M0 8C0 3.58173 3.58172 0 8 0H586.79C590.772 0 594 3.06596 594 6.84801V421.152C594 424.934 590.772 428 586.79 428H8C3.58172 428 0 424.418 0 420V8Z"
          fill="#DFE9FE"
          fill-opacity="0.6"
        />
        {/* </g> */}
        <path
          d="M38.2153 17.9061C38.2153 15.7488 36.3763 14 34.1077 14C31.8391 14 30 15.7488 30 17.9061C30 20.0633 31.8391 21.8121 34.1077 21.8121C36.3763 21.8121 38.2153 20.0633 38.2153 17.9061Z"
          fill="#EB6A5D"
        />
        <path
          d="M52.5923 17.9061C52.5923 15.7488 50.7532 14 48.4846 14C46.216 14 44.377 15.7488 44.377 17.9061C44.377 20.0633 46.216 21.8121 48.4846 21.8121C50.7532 21.8121 52.5923 20.0633 52.5923 17.9061Z"
          fill="#F3BE4F"
        />
        <path
          d="M66.9692 17.9061C66.9692 15.7488 65.1302 14 62.8616 14C60.593 14 58.7539 15.7488 58.7539 17.9061C58.7539 20.0633 60.593 21.8121 62.8616 21.8121C65.1302 21.8121 66.9692 20.0633 66.9692 17.9061Z"
          fill="#64C354"
        />
        <path
          d="M548.855 18.487C549.007 18.4829 549.146 18.4292 549.259 18.3135L552.562 15.0985C552.657 15.0075 552.709 14.8918 552.709 14.7555C552.709 14.4827 552.484 14.2637 552.197 14.2637C552.058 14.2637 551.923 14.3174 551.823 14.4125L548.859 17.3093L545.887 14.4125C545.787 14.3216 545.656 14.2637 545.513 14.2637C545.226 14.2637 545 14.4827 545 14.7555C545 14.8918 545.052 15.0075 545.148 15.0985L548.455 18.3135C548.572 18.4292 548.703 18.487 548.855 18.487Z"
          fill="#555ABF"
        />
        <path d="M29.9219 32.5522H594.041V32.8778H29.9219V32.5522Z" fill="white" fill-opacity="0.2" />
        <path
          d="M574.182 20.9154C576.646 20.9154 578.68 18.9814 578.68 16.6425C578.68 14.3035 576.642 12.3696 574.178 12.3696C571.718 12.3696 569.689 14.3035 569.689 16.6425C569.689 18.9814 571.723 20.9154 574.182 20.9154V20.9154ZM574.182 20.0641C572.188 20.0641 570.593 18.5392 570.593 16.6425C570.593 14.7457 572.188 13.225 574.178 13.225C576.173 13.225 577.776 14.7457 577.781 16.6425C577.785 18.5392 576.177 20.0641 574.182 20.0641V20.0641ZM574.087 17.4649C574.326 17.4649 574.478 17.3326 574.491 17.1631C574.491 17.1467 574.491 17.1301 574.495 17.1136C574.508 16.9029 574.66 16.7582 574.943 16.5846C575.373 16.3201 575.647 16.0805 575.647 15.6052C575.647 14.9152 574.995 14.5226 574.222 14.5226C573.478 14.5226 572.97 14.8449 572.835 15.2292C572.809 15.3036 572.792 15.3739 572.792 15.4482C572.792 15.6466 572.957 15.7747 573.157 15.7747C573.413 15.7747 573.47 15.6507 573.604 15.5102C573.744 15.3036 573.926 15.1838 574.182 15.1838C574.526 15.1838 574.752 15.3697 574.752 15.6466C574.752 15.8987 574.569 16.035 574.2 16.2747C573.891 16.4813 573.665 16.692 573.665 17.0639V17.1094C573.665 17.3449 573.817 17.4649 574.087 17.4649V17.4649ZM574.078 18.7335C574.356 18.7335 574.587 18.5434 574.587 18.2789C574.587 18.0144 574.361 17.8243 574.078 17.8243C573.796 17.8243 573.565 18.0186 573.565 18.2789C573.565 18.5392 573.796 18.7335 574.078 18.7335Z"
          fill="#555ABF"
        />
        <path
          d="M547.5 127H52.5001C40.0736 127 30 130.383 30 134.556V391.444C30 395.617 40.0736 399 52.5001 399H547.5C559.926 399 570 395.617 570 391.444V134.556C570 130.383 559.926 127 547.5 127Z"
          fill="#82A8FF"
        />
        <path
          d="M187.5 304.822H69.5C68.1193 304.822 67 305.887 67 307.2C67 308.513 68.1193 309.577 69.5 309.577H187.5C188.881 309.577 190 308.513 190 307.2C190 305.887 188.881 304.822 187.5 304.822Z"
          fill="white"
          fill-opacity="0.6"
        />
        <path
          d="M132.5 317.184H70.5C69.1193 317.184 68 318.248 68 319.561C68 320.874 69.1193 321.939 70.5 321.939H132.5C133.881 321.939 135 320.874 135 319.561C135 318.248 133.881 317.184 132.5 317.184Z"
          fill="white"
          fill-opacity="0.6"
        />
        <path
          d="M162.571 332.399H69.3907C68.6227 332.399 68 332.991 68 333.721C68 334.452 68.6227 335.044 69.3907 335.044H162.571C163.339 335.044 163.961 334.452 163.961 333.721C163.961 332.991 163.339 332.399 162.571 332.399Z"
          fill="white"
          fill-opacity="0.3"
        />
        <path
          d="M89.9075 358.981C89.9075 353.228 85.0033 348.564 78.9537 348.564C72.9041 348.564 68 353.228 68 358.981C68 364.733 72.9041 369.397 78.9537 369.397C85.0033 369.397 89.9075 364.733 89.9075 358.981Z"
          fill="white"
          fill-opacity="0.7"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M512 204.153C530.225 204.153 545 190.239 545 173.076C545 155.913 530.225 142 512 142C493.775 142 479 155.913 479 173.076C479 190.239 493.775 204.153 512 204.153ZM513.5 161.978C496.61 168.394 496.4 177.587 513.395 183.772C482.2 179.793 481.978 164.791 513.5 161.978ZM509.028 191.237C527.872 180.666 527.802 165.156 508.783 154.914C542.54 164.398 542.374 178.556 509.028 191.237Z"
          fill="white"
        />
        <path
          d="M261.853 156.479H70.1473C68.4091 156.479 67 157.818 67 159.471V164.897C67 166.55 68.4091 167.89 70.1473 167.89H261.853C263.591 167.89 265 166.55 265 164.897V159.471C265 157.818 263.591 156.479 261.853 156.479Z"
          fill="white"
          fill-opacity="0.6"
        />
        <path
          d="M202.495 181.203H69.1885C67.9798 181.203 67 182.134 67 183.284V187.056C67 188.206 67.9798 189.138 69.1885 189.138H202.495C203.704 189.138 204.684 188.206 204.684 187.056V183.284C204.684 182.134 203.704 181.203 202.495 181.203Z"
          fill="white"
          fill-opacity="0.6"
        />
        <path
          d="M158.486 55H37.5138C33.364 55 30 57.8974 30 61.4715V95.1236C30 98.6977 33.364 101.595 37.5138 101.595H158.486C162.636 101.595 166 98.6977 166 95.1236V61.4715C166 57.8974 162.636 55 158.486 55Z"
          fill="#555ABF"
          fill-opacity="0.43"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M53.693 91.3537C61.255 91.3537 67.385 85.5241 67.385 78.3332C67.385 71.1423 61.255 65.313 53.693 65.313C46.131 65.313 40 71.1423 40 78.3332C40 85.5241 46.131 91.3537 53.693 91.3537Z"
          fill="white"
          fill-opacity="0.1"
          stroke="white"
          stroke-opacity="0.3"
          stroke-width="0.821532"
        />
        <path
          d="M118.734 81.2422H75.6212C74.8531 81.2422 74.2305 81.8343 74.2305 82.5647C74.2305 83.2951 74.8531 83.8872 75.6212 83.8872H118.734C119.502 83.8872 120.125 83.2951 120.125 82.5647C120.125 81.8343 119.502 81.2422 118.734 81.2422Z"
          fill="white"
          fill-opacity="0.3"
        />
        <path
          d="M140.98 86.4917H75.942C74.9967 86.4917 74.2305 87.2204 74.2305 88.1192C74.2305 89.0181 74.9967 89.7468 75.942 89.7468H140.98C141.925 89.7468 142.691 89.0181 142.691 88.1192C142.691 87.2204 141.925 86.4917 140.98 86.4917Z"
          fill="black"
          fill-opacity="0.2"
        />
        <path
          d="M114.964 87.1426H75.941C75.3738 87.1426 74.9141 87.5798 74.9141 88.1191C74.9141 88.6584 75.3738 89.0956 75.941 89.0956H114.964C115.531 89.0956 115.991 88.6584 115.991 88.1191C115.991 87.5798 115.531 87.1426 114.964 87.1426Z"
          fill="#5DDBF9"
          stroke="black"
          stroke-opacity="0.05"
          stroke-width="0.247119"
        />
        <path
          d="M116.907 66.7867C116.979 66.5279 117.008 66.2282 117.008 65.9694C117.008 64.3894 115.817 63.5586 114.068 63.5586C112.476 63.5586 111.243 64.4167 110.884 66.1874L109.407 73.4062H110.798L111.745 68.7889H115.616L115.846 67.6039H111.988L112.247 66.3099C112.462 65.2339 113.135 64.7572 114.01 64.7572C114.971 64.7572 115.631 65.1794 115.631 66.1193C115.631 66.2827 115.616 66.5142 115.53 66.7867H116.907ZM118.933 66.6777H117.557L116.897 69.8785C116.84 70.1509 116.797 70.4778 116.797 70.7911C116.797 72.6163 118.202 73.5833 120.052 73.5833C121.945 73.5833 123.35 72.5482 123.766 70.5187L124.554 66.6777H123.178L122.403 70.3961C122.131 71.7309 121.27 72.3847 120.109 72.3847C118.962 72.3847 118.187 71.7582 118.187 70.6685C118.187 70.4233 118.231 70.1509 118.259 70.0147L118.933 66.6777ZM130.203 73.4062H131.58L132.239 70.2054C132.297 69.933 132.34 69.6061 132.34 69.2928C132.34 67.4677 130.92 66.5006 129.085 66.5006C127.178 66.5006 125.787 67.5358 125.371 69.5652L124.582 73.4062H125.959L126.733 69.6879C127.005 68.353 127.866 67.6992 129.027 67.6992C130.174 67.6992 130.949 68.3258 130.949 69.4154C130.949 69.6606 130.906 69.933 130.877 70.0692L130.203 73.4062ZM136.913 73.5833C138.734 73.5833 140.24 72.5618 140.785 70.9681H139.409C138.95 71.8535 138.075 72.3847 137.028 72.3847C135.58 72.3847 134.72 71.5266 134.72 70.3552C134.72 68.9387 135.824 67.6992 137.387 67.6992C138.534 67.6992 139.337 68.2577 139.595 69.1158H141C140.742 67.4949 139.279 66.5006 137.473 66.5006C135.15 66.5006 133.328 68.2441 133.328 70.4506C133.328 72.2213 134.734 73.5833 136.913 73.5833Z"
          fill="#FCFCFC"
        />
        <path
          d="M82.6547 66.8595C82.6547 67.9628 82.2873 68.7974 81.5526 69.3632C80.8279 69.9197 79.8351 70.1978 78.5742 70.1978H77.4423V73.4655H75V63.6626H78.2466C79.6762 63.6626 80.7683 63.9314 81.5228 64.4689C82.2774 64.997 82.6547 65.7939 82.6547 66.8595ZM80.1527 66.8595C80.1527 65.8316 79.5323 65.3176 78.2912 65.3176H77.4423V68.5286H78.4104C78.9663 68.5286 79.3933 68.3919 79.6911 68.1184C79.9989 67.845 80.1527 67.4253 80.1527 66.8595Z"
          fill="#FCFCFC"
        />
        <path
          d="M85.8746 71.5838C85.8746 71.8967 85.9325 72.1208 86.0482 72.2561C86.1729 72.383 86.3554 72.4464 86.5958 72.4464C86.8094 72.4464 87.0275 72.383 87.2501 72.2561C87.4727 72.1208 87.6552 71.939 87.7976 71.7106V66.9793H89.9076V73.7148H88.0647L87.9712 72.9284C87.7398 73.2497 87.4281 73.4992 87.0364 73.6768C86.6447 73.8459 86.2307 73.9304 85.7945 73.9304C85.1268 73.9304 84.6193 73.7486 84.2721 73.385C83.9338 73.013 83.7646 72.4971 83.7646 71.8375V66.9793H85.8746V71.5838ZM97.1805 66.7764C97.6078 66.7764 97.9995 66.844 98.3556 66.9793L97.9684 69.7953H96.6329V68.5649C96.2412 68.6241 95.894 68.8228 95.5913 69.161C95.2886 69.4993 95.0527 69.9263 94.8835 70.4422V72.3703H96.2857V73.7148H91.8387V72.3703H92.7735V68.3112H91.8387V66.9793H94.3894L94.7633 68.4888C95.0215 67.9137 95.3465 67.4867 95.7382 67.2076C96.1299 66.9201 96.6107 66.7764 97.1805 66.7764ZM101.609 70.9369C101.742 71.9601 102.312 72.4717 103.318 72.4717C103.923 72.4717 104.529 72.2857 105.134 71.9136L105.976 73.0045C105.629 73.292 105.214 73.5203 104.734 73.6894C104.253 73.8501 103.723 73.9304 103.145 73.9304C102.352 73.9304 101.68 73.7825 101.128 73.4865C100.576 73.1821 100.158 72.7635 99.8728 72.2307C99.5968 71.6895 99.4588 71.0637 99.4588 70.3534C99.4588 69.6769 99.5923 69.068 99.8594 68.5268C100.135 67.9772 100.532 67.5459 101.048 67.233C101.573 66.9117 102.196 66.751 102.918 66.751C103.594 66.751 104.182 66.8905 104.68 67.1696C105.179 67.4402 105.562 67.8376 105.829 68.3619C106.105 68.8778 106.243 69.4951 106.243 70.2139C106.243 70.4929 106.229 70.734 106.203 70.9369H101.609ZM102.918 68.0955C102.535 68.0955 102.228 68.2309 101.996 68.5015C101.765 68.7636 101.627 69.1695 101.582 69.7192H104.186C104.169 68.6368 103.746 68.0955 102.918 68.0955Z"
          fill="#FCFCFC"
        />
        <rect x="177" y="55" width="393" height="47" rx="8" fill="#555ABF" fill-opacity="0.43" />
        <path
          d="M227.224 63.5586H186.189C184.98 63.5586 184 64.4903 184 65.6397V65.6397C184 66.7891 184.98 67.7208 186.189 67.7208H227.224C228.432 67.7208 229.412 66.7891 229.412 65.6397C229.412 64.4903 228.432 63.5586 227.224 63.5586Z"
          fill="white"
          fill-opacity="0.6"
        />
        <path
          d="M278.571 73.0107H185.391C184.623 73.0107 184 73.6028 184 74.3332C184 75.0636 184.623 75.6557 185.391 75.6557H278.571C279.339 75.6557 279.961 75.0636 279.961 74.3332C279.961 73.6028 279.339 73.0107 278.571 73.0107Z"
          fill="white"
          fill-opacity="0.3"
        />
        <defs>
          <filter
            id="filter0_b"
            x="-4"
            y="-4"
            width="602"
            height="436"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
            <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
          </filter>
        </defs>
      </svg>
    </ScreenWrapper>
  )
}

const ScreenWrapper = styled.div`
  transition: 0.6s ease all;
  position: relative;
  transform: perspective(800px) rotateY(-15deg) translateY(-50px) rotateX(10deg) scale(1);
  border-radius: var(--cardRadius);
  svg {
    border-radius: var(--cardRadius);
    box-shadow: var(--elevation-4);
  }
  @media (max-width: 880px) {
    transform: perspective(800px) rotateY(-15deg) translateY(-20px) rotateX(10deg) scale(0.75);
  }
`
